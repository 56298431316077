
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {Field} from "vee-validate";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import ImageUpload from "@/components/base/upload/ImageUpload.vue";
import UserService from "@/core/services/UserService";
import {Mutations} from "@/store/enums/StoreEnums";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {BACKGROUNDS} from "@/core/config/Constant";
import TakePicture from "@/components/base/upload/TakePicture.vue";
import {RegionSelect} from "@/core/composite/composite";
import BaseRadio from "@/components/base/form/BaseRadio.vue";

export const DATE_FORMAT = [
  {value: 'MM/DD/yyyy', display: '12/30/2022'},
  {value: 'DD/MM/yyyy', display: '30/12/2022'},
  {value: 'MM/DD/yy', display: '12/30/22'},
  {value: 'DD/MM/yy', display: '30/12/22'},
  {value: 'MM-DD-yyyy', display: '12-30-2022'},
  {value: 'DD-MM-yyyy', display: '30-12-2022'},
  {value: 'MM-DD-yy', display: '12-30-22'},
  {value: 'DD-MM-yy', display: '30-12-22'},
  {value: 'DD MMM,yyyy', display: '30 Dec,2022'},
  {value: 'DD MMM,yy', display: '30 Dec,22'},
]
export default defineComponent({
  name: "account-settings",
  components: {
    BaseRadio,
    TakePicture,
    BaseSelect,
    ImageUpload,
    FormErrorMessage,
    BaseForm,
    Field,
  },
  setup() {
    const submitting = ref(false);
    const profile = computed(() => store.getters.currentUser)

    const model = ref({
        countryCode: profile?.value?.countryCode ? profile?.value?.countryCode : 'CA',
        codeType: profile?.value?.codeType ? profile?.value?.codeType : 'EMAIL',
        avatar: profile.value?.avatar,
        firstName: profile.value?.firstName,
        lastName: profile.value?.lastName,
        phone: profile.value?.nationalFormat,
        dateFormat: profile.value?.dateFormat,
        background: profile.value?.background,
      }
    )

    watch(profile, (cb) => {
      model.value.avatar = cb.avatar;
      model.value.firstName = cb.firstName;
      model.value.lastName = cb.lastName;
      model.value.phone = cb.nationalFormat;
      model.value.dateFormat = cb.dateFormat;
      model.value.background = cb.background;
      model.value.countryCode = cb.countryCode ? cb.countryCode : 'CA'
      model.value.codeType = cb.codeType ? cb.codeType : 'EMAIL'
    })

    const onSave = async () => {
      submitting.value = true;
      await UserService.updateProfile(model.value).then(res => {
        store.commit(Mutations.SET_PROFILE, res)
      }).finally(() => {
        submitting.value = false;
      })
    }

    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Settings", [])
    })

    return {
      model,
      submitting,
      onSave,
      DATE_FORMAT,
      BACKGROUNDS,
      ...RegionSelect(),

    };
  },
  methods: {
    onTakePicture(data) {
      this.model.avatar = data.link
    },
    onSelectBackground(e) {
      store.commit('SET_BACKGROUND', e.target.value)
    }
  }
});
